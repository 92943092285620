import {
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import React, { useEffect, useState } from "react";
import memberAdminServices from "../../../services/memberSubmission/admin";
import { memberSubmissionListTableColumn } from "../../../helpers/constant";
import { Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import { CustomDialog, CustomIconMenu, Loader } from "../../../components";
import { saveAs } from "file-saver";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.surface,
    color: theme.palette.neutral[90],
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.info.main,
  cursor: "pointer",
}));

const WarningDescText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral[90],
}));

const SubmissionMemberList = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [deleteMember, setDeleteMember] = useState({
    id: null,
    warningOpen: false,
    loading: false,
  });

  const [memberList, setMemberList] = useState({
    data: [],
    loading: false,
    filter: {
      sortBy: "",
      sortDirection: "",
      page: 0,
      rowsPerPage: 10,
      totalDocs: 0,
    },
    refresh: false,
  });

  useEffect(() => {
    const fetchAllMembers = async () => {
      setMemberList((prev) => ({
        ...prev,
        loading: true,
      }));

      const reqAllMembers = {
        searchKey: "",
        sortBy: memberList.filter.sortBy,
        sortDirection: memberList.filter.sortDirection,
        page: memberList.filter.page + 1,
        limit: memberList.filter.rowsPerPage,
      };

      const resAllMembers =
        await memberAdminServices.getAllMemberSubmissionList(reqAllMembers);

      if (resAllMembers?.data?.docs?.length > 0) {
        setMemberList((prev) => ({
          ...prev,
          data: resAllMembers.data.docs,
          loading: false,
        }));
      } else {
        setMemberList((prev) => ({
          ...prev,
          data: [],
          loading: false,
        }));
      }
    };
    fetchAllMembers();
  }, [
    memberList.filter.page,
    memberList.filter.rowsPerPage,
    memberList.refresh,
    memberList.filter.sortBy,
    memberList.filter.sortDirection,
  ]);

  const handleChangePage = (event, newPage) => {
    setMemberList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        page: newPage,
      },
    }));
  };

  const handleChangeRowsPerPage = (event) => {
    setMemberList((prev) => ({
      ...prev,
      filter: {
        ...prev.filter,
        rowsPerPage: event.target.value,
        page: 0,
      },
    }));
  };

  const onClickDeleteMember = async () => {
    if (!deleteMember?.id) {
      return;
    }
    setDeleteMember((prev) => ({
      ...prev,
      loading: true,
    }));

    const deleteRes = await memberAdminServices.deleteMemberSubmissionById({
      memberSubmissionId: deleteMember?.id,
    });

    if (deleteRes?.data) {
      setMemberList((prev) => ({
        ...prev,
        refresh: !prev.refresh,
      }));
      setDeleteMember((prev) => ({
        ...prev,
        id: null,
        warningOpen: false,
        loading: false,
      }));
    } else {
      setDeleteMember((prev) => ({
        ...prev,
        loading: false,
      }));
    }
  };

  const rowActionOptions = (rowData) => [
    {
      name: "Download photo",
      handleOption: () => {
        saveAs(
          `${process.env.REACT_APP_FILE_BASE_URL}${rowData.imgUrl}`,
          `${rowData.name}_image`
        );
      },
    },
    {
      name: "Download CV",
      handleOption: () => {
        saveAs(
          `${process.env.REACT_APP_FILE_BASE_URL}${rowData.cvUrl}`,
          `${rowData.name}_cv`
        );
      },
    },
    {
      name: "Delete",
      handleOption: () => {
        setDeleteMember((prev) => ({
          ...prev,
          id: rowData._id,
          warningOpen: true,
        }));
      },
    },
  ];

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {memberSubmissionListTableColumn?.map(
                (memberListColumn, memberListColumnIndex) => (
                  <StyledTableCell
                    size="small"
                    key={`${memberListColumnIndex}_${memberListColumn}`}
                  >
                    {memberListColumn}
                  </StyledTableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {memberList?.loading ? (
              <StyledTableRow
                key={`subject_not_found`}
                sx={{ height: 30 * memberList?.filter?.rowsPerPage }}
              >
                <StyledTableCell
                  scope="row"
                  colSpan={7}
                  sx={{ textAlign: "center" }}
                >
                  <Loader />
                </StyledTableCell>
              </StyledTableRow>
            ) : memberList?.data?.length > 0 ? (
              memberList?.data?.map((memberRow) => (
                <StyledTableRow key={`${memberRow._id}${memberRow.name}`}>
                  <StyledTableCell size="small" component="th" scope="row">
                    <StyledTitle
                      onClick={() =>
                        navigate(`/admin/member/edit?memberId=${memberRow._id}`)
                      }
                    >
                      {memberRow.name}
                    </StyledTitle>
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {memberRow?.joinType}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {memberRow?.journal?.name}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {memberRow?.designation}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {`${memberRow?.email},`}
                    <br /> {memberRow?.phone}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    {moment(memberRow?.createdAt).format("DD-MM-YYYY")}
                  </StyledTableCell>
                  <StyledTableCell size="small">
                    <CustomIconMenu options={rowActionOptions(memberRow)} />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow key={`subject_not_found`}>
                <StyledTableCell
                  size="small"
                  scope="row"
                  colSpan={7}
                  sx={{ textAlign: "center" }}
                >
                  No available data
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={memberList?.data?.length}
          rowsPerPage={memberList?.filter?.rowsPerPage}
          page={memberList?.filter?.page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          sx={{
            "& .MuiToolbar-root": {
              minHeight: "48px",
            },
          }}
        />
      </TableContainer>
      <CustomDialog
        open={deleteMember.warningOpen}
        title={"Are you sure ?"}
        agreeButtonText={"Delete"}
        agreeButtonProp={{
          variant: "contained",
          color: "secondary",
          type: "button",
          endIcon: deleteMember.loading && <CircularProgress size={20} />,
          disabled: deleteMember.loading,
          onClick: () => onClickDeleteMember(),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              // backgroundColor: theme.palette.secondary.main,
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.secondary.hover,
              },
            },
          },
        }}
        cancelButtonText={"Cancel"}
        cancelButtonProp={{
          variant: "contained",
          color: "primary",
          type: "button",
          disabled: deleteMember.loading,
          onClick: () =>
            setDeleteMember((prev) => ({
              ...prev,
              id: null,
              warningOpen: false,
              loading: false,
            })),
          size: "small",
          sx: {
            "&.MuiButton-root": {
              color: theme.palette.neutral[0],
              // width: "100%",
              borderRadius: theme.borderRadius[10],
              // backgroundColor: theme.palette.primary.main,
              textTransform: "none",
              "&:hover": {
                backgroundColor: theme.palette.primary.hover,
              },
            },
          },
        }}
      >
        <WarningDescText>
          Do you really want to delete this record? This process cannot be
          undone.
        </WarningDescText>
      </CustomDialog>
    </Box>
  );
};

export default SubmissionMemberList;
