import React, { useState } from "react";
import {
  AppBar,
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
  Collapse,
  ListItemIcon,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import LogoOnly from "../../../assets/logo/logoOnly.png";
import LogoTextOnly from "../../../assets/logo/textOnly.png";
import { navbarMenu } from "../../../helpers/constant";
import {
  useNavigate,
  useParams,
  matchPath,
  useLocation,
} from "react-router-dom";
import CustomMenu from "../../../components/CustomMenu";
import { useSelector } from "react-redux";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  boxShadow: theme.boxShadow.shadowTwo,
  backgroundColor: theme.palette.neutral[0],
  "& .MuiToolbar-root": {
    justifyContent: "space-between",
    maxWidth: "1600px",
    margin: "0 auto",
    width: "100%",
  },
}));

const drawerWidth = 240;

const navItems2 = () => [
  {
    isIcon: false,
    name: "Submit Article",
    route: "/article/submit",
  },
];

// journal paths
let includesJournalPath = (pathname) =>
  [
    "/journal/:journalId/author-guidelines",
    "/journal/:journalId/reviewer-guidelines",
  ].includes(pathname);

const Header = ({ window }) => {
  const theme = useTheme();
  const mediaQuery1020 = useMediaQuery("(min-width:1020px)");
  const mediaQuery825 = useMediaQuery("(min-width:825px)");

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { journalId, issueId, articleId } = useParams();

  const { journalDetails } = useSelector((state) => state.webJournalReducer);

  const [memberShipMenuOpen, setMemberShipMenuOpen] = useState(false);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    setMemberShipMenuOpen(false);
  };

  const handleClickMemberShipMenuOpen = () => {
    setMemberShipMenuOpen((prev) => !prev);
  };

  const drawer = (
    <Box
      // onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
    >
      <Box sx={{ p: 1 }}>
        <img
          alt="ifr-logo-text"
          src={LogoTextOnly}
          width="130px"
          height="20px"
        />
      </Box>
      <Divider
        variant="middle"
        sx={{ borderColor: (theme) => theme.palette.secondary.main }}
      />
      <List>
        {navbarMenu(
          {
            journalId,
            journalCustomId: journalDetails?.journalCustomId,
            issueId,
            articleId,
            pathname,
          },
          navigate
        ).map((item) => (
          <>
            <ListItem key={item.name} disablePadding>
              <ListItemButton
                sx={{
                  textAlign: "center",
                  "&.Mui-selected": {
                    background: theme.palette.primary.surface,
                  },
                }}
                onClick={() => {
                  if (item.type === "menu") {
                    handleClickMemberShipMenuOpen();
                  } else {
                    handleDrawerToggle();
                    navigate(item.route);
                  }
                  // item.type === "menu"
                  //   ? handleClickMemberShipMenuOpen()
                  //   : navigate(item.route)
                }}
                selected={item.route && matchPath(item.route, pathname)}
              >
                <ListItemText primary={item.name} />
                {
                  item.type === "menu" &&
                    // <ListItemIcon>
                    (memberShipMenuOpen ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    ))
                  // </ListItemIcon>
                }
              </ListItemButton>
            </ListItem>
            {item.type === "menu" && (
              <Collapse in={memberShipMenuOpen} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  disablePadding
                  sx={{ background: theme.palette.neutral[10] }}
                >
                  {item?.menuOptions?.map((itemMenuOption) => (
                    <ListItemButton
                      sx={{ textAlign: "center" }}
                      onClick={() => {
                        itemMenuOption.onSelect();
                        handleDrawerToggle();
                      }}
                    >
                      <ListItemText primary={itemMenuOption.name} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            )}
          </>
        ))}
      </List>
      {navItems2().map(
        (item) =>
          !item.isIcon && (
            <Button
              key={item}
              sx={{
                "&.MuiButton-root": {
                  color: theme.palette.neutral[0],
                  height: "35px",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  borderRadius: theme.borderRadius[10],
                  backgroundColor: theme.palette.secondary.main,
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.hover,
                  },
                },
              }}
              size="small"
              startIcon={<i class="ri-newspaper-fill"></i>}
              onClick={() => navigate(item.route)}
            >
              {item.name}
            </Button>
          )
      )}
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <div>
      <CssBaseline />
      <StyledAppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: mediaQuery825 ? "none" : "block" }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ...((journalId ||
                journalDetails?.journalCustomId ||
                issueId ||
                articleId) && {
                marginRight: "20px",
              }),
            }}
          >
            <img alt="ifr-logo" src={LogoOnly} width="40px" />
            <img
              alt="ifr-logo-text"
              src={LogoTextOnly}
              width="130px"
              height="20px"
            />
          </Box>
          <Box
            sx={{
              display: mediaQuery825 ? "flex" : "none",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
            }}
          >
            {navbarMenu(
              {
                journalId,
                journalCustomId: journalDetails?.journalCustomId,
                issueId,
                articleId,
                pathname,
              },
              navigate
            ).map((item) =>
              item.type === "button" ? (
                <Button
                  key={item.name}
                  sx={{
                    "&.MuiButton-root": {
                      // marginRight: "10px",
                      color: theme.palette.neutral[100],
                      textTransform: "none",
                      height: "30px",
                      borderRadius: theme.borderRadius[10],

                      ...(matchPath(item.route, pathname) && {
                        color: theme.palette.neutral[100],
                        backgroundColor: theme.palette.primary.surface,
                      }),

                      "&:hover": {
                        color: theme.palette.neutral[100],
                        backgroundColor: theme.palette.primary.main,
                      },
                    },
                  }}
                  onClick={() => navigate(item.route)}
                >
                  {item.name}
                </Button>
              ) : (
                <CustomMenu
                  menuButtonText={item.name}
                  options={item.menuOptions}
                  showMenuArrow
                  buttonProps={{
                    sx: {
                      "&.MuiButton-root": {
                        // marginRight: "10px",
                        color: theme.palette.neutral[100],
                        textTransform: "none",
                        height: "30px",
                        borderRadius: theme.borderRadius[10],
                        "&:hover": {
                          color: theme.palette.neutral[100],
                          backgroundColor: theme.palette.primary.main,
                        },
                      },
                    },
                  }}
                />
              )
            )}
          </Box>
          <Box
            sx={{
              ...((!mediaQuery1020 ||
                journalId ||
                issueId ||
                articleId ||
                includesJournalPath(pathname)) && {
                display: "none",
              }),
            }}
          >
            {navItems2().map(
              (item) =>
                !item.isIcon && (
                  <Button
                    key={item}
                    sx={{
                      "&.MuiButton-root": {
                        color: theme.palette.neutral[0],
                        height: "35px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                        borderRadius: theme.borderRadius[10],
                        backgroundColor: theme.palette.secondary.main,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: theme.palette.secondary.hover,
                        },
                      },
                    }}
                    size="small"
                    startIcon={<i class="ri-newspaper-fill"></i>}
                    onClick={() => navigate(item.route)}
                  >
                    {item.name}
                  </Button>
                )
            )}
          </Box>
        </Toolbar>
      </StyledAppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: mediaQuery825 ? "none" : "block",
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </div>
  );
};

export default Header;
