import React, { useState } from "react";
import {
  Box,
  useTheme,
  Button,
  CircularProgress,
  Typography,
  InputAdornment,
} from "@mui/material";
import { CustomTextField } from "../../components";
import LogoOnly from "../../assets/logo/logoOnly.png";
import LogoTextOnly from "../../assets/logo/textOnly.png";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import authServices from "../../services/auth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PasswordIcon from "@mui/icons-material/Password";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

function AdminLogin() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loginLoader, setLoginLoader] = useState(false);

  const loginValidationSchema = yup.object().shape({
    email: yup.string().email().required("email is required"),
    password: yup.string().required("password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(loginValidationSchema),
  });

  const onSubmit = async (data) => {
    setLoginLoader(true);
    const resLogin = await authServices.login(data);

    if (resLogin) {
      let { accessToken, userId } = resLogin;
      localStorage.setItem("ifrAccessToken", accessToken);
      localStorage.setItem("userId", userId);
      toast.success("Login successful");
      navigate("/admin/home");
      setLoginLoader(false);
    } else {
      setLoginLoader(false);
    }
  };

  const currentDate = new Date();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Box
        sx={{
          width: "40%",
          display: "flex",
          alignItems: "center",
          justify: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justify: "center",
            flexDirection: "column",
            // background: theme.palette.secondary.surface,
            width: "70%",
            height: "100%",
            // boxShadow: `0px 0px 5px ${theme.palette.neutral[50]}`,
            // borderRadius: theme.borderRadius[10],
            padding: "30px",
          }}
        >
          <Box
            sx={{
              marginBottom: "20px",
              textAlign: "left",
              width: "100%",
            }}
          >
            <Box>
              <Typography
                variant="h4"
                fontWeight={600}
                color={theme.palette.neutral[90]}
              >
                Hi, Admin 👋
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="h6"
                gutterBottom
                fontWeight={600}
                color={theme.palette.neutral[50]}
              >
                Welcome back!
              </Typography>
            </Box>
          </Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  label="Email"
                  variant="standard"
                  size="small"
                  type="text"
                  placeholder="Enter your email..."
                  fullWidth
                  sx={{
                    marginBottom: "20px",
                    background: theme.palette.neutral[0],
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AlternateEmailIcon />
                      </InputAdornment>
                    ),
                  }}
                  helperText={errors?.email?.message}
                  error={errors?.email?.message}
                  {...field}
                />
              )}
            />

            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <CustomTextField
                  id="outlined-basic"
                  label="Password"
                  variant="standard"
                  size="small"
                  type="password"
                  placeholder="Enter your password..."
                  fullWidth
                  sx={{
                    marginBottom: "30px",
                    background: theme.palette.neutral[0],
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PasswordIcon />
                      </InputAdornment>
                    ),
                  }}
                  helperText={errors?.password?.message}
                  error={errors?.password?.message}
                  {...field}
                />
              )}
            />

            <Button
              variant="contained"
              color="secondary"
              sx={{
                "&.MuiButton-root": {
                  color: theme.palette.neutral[0],
                  height: "35px",
                  width: "100%",
                  paddingLeft: "40px",
                  paddingRight: "40px",
                  borderRadius: theme.borderRadius[10],
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.hover,
                  },
                },
              }}
              size="small"
              type="submit"
              endIcon={loginLoader && <CircularProgress size={20} />}
              disabled={loginLoader}
            >
              Login Now
            </Button>
          </form>
          {/* <Typography
            sx={{ margin: "10px 0", textAlign: "center" }}
            variant="body2"
            color={theme.palette.neutral[50]}
          >
            {`IFRD International llp © ${currentDate.getFullYear()}`}
          </Typography> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "60%",
          height: "100vh",
          backgroundColor: theme.palette.primary.surface,
          // backgroundColor: "#e5e5f7",
          // // opacity: 0.7,
          // backgroundImage:
          //   "repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 40px ), repeating-linear-gradient( #ffc5b355, #ffc5b3 )",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img alt="ifr-logo" src={LogoOnly} width="50%" />
          <img alt="ifr-logo-text" src={LogoTextOnly} width="50%" />
        </Box>
      </Box>
    </Box>
  );
}

export default AdminLogin;
