import axiosInstance from "../../axiosInstance";

const createMemberSubmission = async (data) => {
  try {
    const res = await axiosInstance.post(
      `/api/memberSubmission/websiteCreateMemberSubmission`,
      data
    );

    if (res.status === 201) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default createMemberSubmission;
