import axiosInstance from "../../axiosInstance";

/**
 * Delete member submission by Id
 * @param {*} memberSubmissionId
 */
const deleteMemberSubmissionById = async ({ memberSubmissionId }) => {
  try {
    const res = await axiosInstance.delete(
      `/api/memberSubmission/deleteMemberSubmissionById?memberSubmissionId=${memberSubmissionId}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default deleteMemberSubmissionById;
