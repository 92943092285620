import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography,
  styled,
  useTheme,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CustomDialog,
  CustomReactSelect,
  CustomTextField,
} from "../../../components";
import { useDropzone } from "react-dropzone";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import journalWebsiteServices from "../../../services/journal/website";
import {
  submitArticleTypeOptions,
  copyrightFormTC,
} from "../../../helpers/constant";
import articleSubmissionWebsiteServices from "../../../services/articleSubmission/website";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useSearchParams } from "react-router-dom";
import CopyRightSignature from "../../../assets/logo/CopyRightSignature.png";
import IFRDstamp from "../../../assets/logo/IFRDstamp.png";
import { toast } from "react-toastify";
import { fileFormatCheck } from "../../../utils";

const StyledDropZoneContainer = styled(Box, {
  shouldForwardProp: (props) => ["error"].indexOf(props) === -1,
})(({ theme, error }) => ({
  border: error
    ? `1px dashed ${theme.palette.danger.main}`
    : `1px dashed ${theme.palette.neutral[70]}`,
  background: theme.palette.neutral[10],
  width: "100%",
  height: "200px",
  borderRadius: theme.borderRadius[3],
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  padding: "10px",
  "& h5 strong": {
    color: theme.palette.danger.main,
  },
  "& p": {
    fontSize: "12px",
  },
}));

const SubmitArticle = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const [articleFile, setArticleFile] = useState({
    file: null,
    error: "",
  });

  const [journalOptions, setJournalOptions] = useState([]);

  const [openCopyRightForm, setOpenCopyRightForm] = useState(false);
  const [authorSign, setAuthorSign] = useState({
    file: null,
    error: "",
  });

  const [submitLoader, setSubmitLoader] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [trackingId, setTrackingId] = useState();

  const journalValidationSchema = yup.object().shape({
    authorName: yup.string().required("Author name is required"),
    phone: yup.string().required("Phone number is required"),
    email: yup.string().required("Email is required"),
    journal: yup.object().shape({
      name: yup.string(),
      _id: yup.string().required("Journal is required"),
    }),
    articleType: yup.object().shape({
      name: yup.string().required("Article Type is required"),
    }),
    manuscriptTitle: yup.string().required("Manuscript Title is required"),
  });

  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      authorName: "",
      phone: "",
      email: "",
      journal: {
        name: "",
        _id: "",
      },
      articleType: {
        name: "",
      },
      manuscriptTitle: "",
    },
    resolver: yupResolver(journalValidationSchema),
  });

  const onSubmit = async (data) => {
    if (!articleFile.file) {
      setArticleFile((prev) => ({
        ...prev,
        error: "Please select a file",
      }));

      return;
    }

    if (!authorSign.file) {
      setAuthorSign((prev) => ({
        ...prev,
        error: "Please select your signature",
      }));

      return;
    }
    setSubmitLoader(true);
    const articleSubmissionFormData = new FormData();
    articleSubmissionFormData.append("authorName", data.authorName);
    articleSubmissionFormData.append("phone", data.phone);
    articleSubmissionFormData.append("email", data.email);
    articleSubmissionFormData.append("journal", data.journal._id);
    articleSubmissionFormData.append("articleType", data.articleType.name);
    articleSubmissionFormData.append("manuscriptTitle", data.manuscriptTitle);
    articleSubmissionFormData.append("manuscriptFile", articleFile.file);
    articleSubmissionFormData.append("authorSign", authorSign.file);

    const resSubmitArticle =
      await articleSubmissionWebsiteServices.createArticleSubmission(
        articleSubmissionFormData
      );
    if (resSubmitArticle) {
      setTrackingId(
        resSubmitArticle?.data?.[0]?.trackingId ||
          resSubmitArticle?.data?.trackingId
      );
      setShowSuccessPage(true);
      setSubmitLoader(false);
      setOpenCopyRightForm(false);
    } else {
      setSubmitLoader(false);
      toast.error(
        <Typography variant="subtitle2">
          Something went wrong, please contact us on{" "}
          <a href={"mailto:office@ifrdiscovery.org"}>office@ifrdiscovery.org</a>{" "}
          for further assistance
        </Typography>,
        {
          autoClose: 10000,
        }
      );
    }
  };

  const onSubmitError = (errors) => {
    const errorList = Object.values(errors);
    toast.error(
      <>
        <Typography variant="subtitle2">
          {"Please recheck your Submission."}
        </Typography>
        <ul>
          {errorList?.map((errorMessage) => (
            <li key={errorMessage}>
              <Typography variant="subtitle2">
                {errorMessage.message}
              </Typography>
            </li>
          ))}
        </ul>
      </>,
      {
        autoClose: 10000,
      }
    );
  };

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      setArticleFile({ file: acceptedFiles[0], error: "" });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      // "application/pdf": [".pdf"],
      // "text/plain": [".txt"],
    },
    multiple: false,
  });

  useEffect(() => {
    const fetchJournalOptions = async () => {
      const resJournalOptions =
        await journalWebsiteServices.getJournalListWebsite({
          subjectId: "",
          sortBy: "",
          sortDirection: "",
          page: "",
          limit: "",
        });

      setJournalOptions(resJournalOptions?.data?.docs);
      if (searchParams.get("journalId")) {
        const preselectedJournal = resJournalOptions?.data?.docs?.find(
          (journal) => journal?._id === searchParams.get("journalId")
        );
        resetField("journal", { defaultValue: preselectedJournal });
      }
    };
    fetchJournalOptions();
  }, []);

  return (
    <Box
      sx={{
        padding: "20px 10%",
        height: "100%",
        background: theme.palette.primary.litSurface,
      }}
    >
      {showSuccessPage ? (
        <Box
          sx={{
            padding: "20px",
            height: "100vh",
            background: theme.palette.neutral[0],
            borderRadius: theme.borderRadius[8],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <CheckCircleIcon
            sx={{ fontSize: 100, color: theme.palette.success.main }}
          />
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Thank you for your submission.
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: 600,
              textAlign: "center",
              "& span": {
                color: theme.palette.secondary.main,
              },
            }}
          >
            Your Manuscript Tracking ID is <span>{trackingId}</span>.
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 600,
              textAlign: "center",
              color: theme.palette.neutral[80],
            }}
          >
            Our team will get in touch with you at your provided email address
            within 24 to 48 hours.
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              padding: "20px",
              height: "100%",
              background: theme.palette.neutral[0],
              borderRadius: theme.borderRadius[8],
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                // fontWeight: 600,
                marginBottom: "50px",
                textAlign: "center",
              }}
            >
              Inspire Discovery, Ignite Change: Submit Your Article today!
            </Typography>
            <form>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Controller
                    name="authorName"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label="Corresponding Author Name *"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        helperText={errors?.authorName?.message}
                        error={errors?.authorName?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <MuiTelInput
                        label="Phone Number with country Code *"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        helperText={errors?.phone?.message}
                        error={errors?.phone?.message}
                        defaultCountry="IN"
                        sx={{
                          "& label": {
                            "&.Mui-focused": {
                              color: theme.palette.neutral[90],
                            },
                          },
                          "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: theme.palette.neutral[50],
                            },
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label="Email *"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        helperText={errors?.email?.message}
                        error={errors?.email?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="journal"
                    control={control}
                    render={({ field }) => (
                      // <CustomTextField
                      //   label="Choose Journal *"
                      //   variant="outlined"
                      //   size="small"
                      //   type="text"
                      //   fullWidth
                      //   helperText={errors?.journal?.message}
                      //   error={errors?.journal?.message}
                      //   {...field}
                      // />
                      <CustomReactSelect
                        label={"Choose Journal *"}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e._id}
                        options={journalOptions}
                        placeholder={"- - -"}
                        helperText={errors?.journal?._id?.message}
                        error={errors?.journal?._id?.message}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 5,
                          }),
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="articleType"
                    control={control}
                    render={({ field }) => (
                      <CustomReactSelect
                        label={"Choose Article Type *"}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.name}
                        options={submitArticleTypeOptions}
                        placeholder={"- - -"}
                        helperText={errors?.articleType?.name?.message}
                        error={errors?.articleType?.name?.message}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 5,
                          }),
                        }}
                        {...field}
                      />
                      // <CustomTextField
                      //   label="Choose Article Type *"
                      //   variant="outlined"
                      //   size="small"
                      //   type="text"
                      //   fullWidth
                      //   helperText={errors?.articleType?.message}
                      //   error={errors?.articleType?.message}
                      //   {...field}
                      // />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Controller
                    name="manuscriptTitle"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label="Manuscript Title *"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        helperText={errors?.manuscriptTitle?.message}
                        error={errors?.manuscriptTitle?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {articleFile.file ? (
                    <StyledDropZoneContainer>
                      <Box sx={{ position: "relative" }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setArticleFile({
                              file: null,
                              error: "Please Select a file.",
                            })
                          }
                          edge="end"
                          sx={{
                            position: "absolute",
                            top: -15,
                            right: -10,
                            zIndex: 1,
                            background: theme.palette.neutral[0],
                            color: theme.palette.secondary.main,
                          }}
                          size="small"
                        >
                          <i class="ri-delete-bin-2-fill ri-sm"></i>
                        </IconButton>
                        <Box
                          sx={{
                            background: theme.palette.neutral[0],
                            padding: "10px 10px",
                            borderRadius: theme.borderRadius[8],
                          }}
                        >
                          <Typography
                            variant="body1"
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            {articleFile?.file?.name}
                          </Typography>
                        </Box>
                      </Box>
                    </StyledDropZoneContainer>
                  ) : (
                    <StyledDropZoneContainer
                      {...getRootProps()}
                      error={articleFile.error}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <Box width={"100%"}>
                          <p>Drop the files here ...</p>
                        </Box>
                      ) : (
                        <Box>
                          {articleFile.error && (
                            <h5>
                              <strong>{articleFile.error}</strong>
                            </h5>
                          )}
                          <h5>Upload Article File *</h5>
                          <h5>
                            <strong>Accepts only .docx, .doc, file type</strong>
                          </h5>

                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        </Box>
                      )}
                    </StyledDropZoneContainer>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{
                      "&.MuiButton-root": {
                        color: theme.palette.neutral[0],
                        width: "100%",

                        borderRadius: theme.borderRadius[10],
                        backgroundColor: theme.palette.secondary.main,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: theme.palette.secondary.hover,
                        },
                      },
                      "&.Mui-disabled": {
                        backgroundColor: theme.palette.neutral[30],
                      },
                    }}
                    size="large"
                    type="button"
                    onClick={() => setOpenCopyRightForm(true)}

                    // disabled={submitLoader}
                    // endIcon={submitLoader && <CircularProgress size={20} />}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
            {/* <Typography
              variant="body2"
              gutterBottom
              sx={{
                marginTop: "50px",
                textAlign: "center",
              }}
            >
              <strong>For Email Submission:</strong> Email us your Article File
              at{" "}
              <a href={"mailto:office@ifrdiscovery.org"}>
                office@ifrdiscovery.org
              </a>{" "}
              and mention the Journal Name in which you want to publish your
              article.
            </Typography> */}
          </Box>
          <CustomDialog
            open={openCopyRightForm}
            title={
              <Typography variant="h6" gutterBottom>
                Declaration of Copyright Form
              </Typography>
            }
            agreeButtonText={"Submit"}
            agreeButtonProp={{
              variant: "contained",
              color: "secondary",
              type: "button",
              // onClick: () => onSubmit(),
              onClick: () => handleSubmit(onSubmit, onSubmitError)(),
              endIcon: submitLoader && <CircularProgress size={20} />,
              disabled: submitLoader || !articleFile.file || !authorSign.file,
              size: "small",
              sx: {
                "&.MuiButton-root": {
                  color: theme.palette.neutral[0],
                  // width: "100%",
                  borderRadius: theme.borderRadius[10],
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.secondary.hover,
                  },
                },
              },
            }}
            cancelButtonText={"Previous"}
            cancelButtonProp={{
              variant: "contained",
              color: "primary",
              type: "button",
              onClick: () => setOpenCopyRightForm(false),
              disabled: submitLoader,
              size: "small",
              sx: {
                "&.MuiButton-root": {
                  color: theme.palette.neutral[0],
                  // width: "100%",
                  borderRadius: theme.borderRadius[10],
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: theme.palette.primary.hover,
                  },
                },
              },
            }}
            dialogProp={{
              maxWidth: "md",
              sx: {
                "& .MuiDialogTitle-root": {
                  background: theme.palette.primary.litSurface,
                },
              },
            }}
          >
            <Box sx={{ marginTop: "20px", marginBottom: "20px" }}>
              <Typography variant="body1" gutterBottom>
                <strong>Article Title:</strong> {getValues("manuscriptTitle")}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Journal Name:</strong> {getValues("journal.name")}
              </Typography>
              <Typography variant="body1" gutterBottom>
                <strong>Corresponding Author:</strong> {getValues("authorName")}
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography variant="body1" gutterBottom>
                I, hereby transfer to ‘International Federation of Research and
                Discovery Publisher’ all rights, including but not limited to
                the copyright, in and to the manuscript identified above for
                publication in the Journal of IFR Discovery Publisher operated
                by IFRD International llp.
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" gutterBottom>
                I understand and agree that:
              </Typography>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
              <ol>
                {copyrightFormTC?.map((copyrightFormText) => (
                  <li>{copyrightFormText.text}</li>
                ))}
              </ol>
            </Box>
            <Box sx={{ marginBottom: "20px" }}>
              <Typography variant="body1" gutterBottom>
                I have read and understand the terms of this Copyright Transfer
                Agreement and hereby agree to be bound by its terms.
              </Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    flexDirection: "column",
                  }}
                >
                  {authorSign.file ? (
                    <StyledDropZoneContainer>
                      <Box sx={{ position: "relative" }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setAuthorSign({
                              file: null,
                              error: "Please Select a file.",
                            })
                          }
                          edge="end"
                          sx={{
                            position: "absolute",
                            top: -15,
                            right: -10,
                            zIndex: 1,
                            background: theme.palette.neutral[0],
                            color: theme.palette.secondary.main,
                          }}
                          size="small"
                        >
                          <i class="ri-delete-bin-2-fill ri-sm"></i>
                        </IconButton>
                        <Box
                          sx={{
                            background: theme.palette.neutral[0],
                            padding: "10px 10px",
                            borderRadius: theme.borderRadius[8],
                          }}
                        >
                          <img
                            alt="ifr-logo"
                            src={URL.createObjectURL(authorSign?.file)}
                            width="150px"
                          />
                        </Box>
                      </Box>
                    </StyledDropZoneContainer>
                  ) : (
                    <>
                      <Typography
                        variant="caption"
                        sx={{
                          marginBottom: "10px",
                          textAlign: "center",
                          "& span": {
                            color: theme.palette.danger.main,
                          },
                        }}
                      >
                        <strong>
                          Accepts only <span>.png, .jpeg, .jpg</span> image type
                        </strong>
                      </Typography>
                      <Button
                        component="label"
                        variant="contained"
                        color="secondary"
                        sx={{
                          "&.MuiButton-root": {
                            color: theme.palette.neutral[0],

                            borderRadius: theme.borderRadius[10],
                            textTransform: "none",
                            "&:hover": {
                              backgroundColor: theme.palette.secondary.hover,
                            },
                          },
                        }}
                      >
                        Upload Your Signature
                        <input
                          hidden
                          type="file"
                          accept="image/png, image/jpeg, image/jpg"
                          onChange={async (e) => {
                            let file = e.target.files[0];
                            if (
                              fileFormatCheck(file, [
                                "image/png",
                                "image/jpeg",
                                "image/jpg",
                              ])
                            ) {
                              setAuthorSign((prev) => ({
                                ...prev,
                                file,
                                error: "",
                              }));
                            } else {
                              setAuthorSign((prev) => ({
                                ...prev,
                                error: "Incorrect file format",
                              }));
                            }
                          }}
                        />
                      </Button>
                      {authorSign?.error && (
                        <Typography
                          variant="caption"
                          sx={{
                            marginTop: "10px",
                            textAlign: "center",
                            "& span": {
                              color: theme.palette.danger.main,
                            },
                          }}
                        >
                          <strong>
                            <span>{authorSign?.error}</span>
                          </strong>
                        </Typography>
                      )}
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img
                    alt="ifrd-stamp"
                    src={IFRDstamp}
                    width="150px"
                    style={{
                      transform: "rotate(-14deg)",
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <img
                    alt="ifrd-managing-director-sign"
                    src={CopyRightSignature}
                    width="150px"
                  />
                  <Typography variant="body2" gutterBottom>
                    <strong>Managing Director</strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CustomDialog>
        </>
      )}
    </Box>
  );
};

export default SubmitArticle;
