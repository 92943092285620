import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import editorWebsiteServices from "../../../services/editor/website";
import { useParams } from "react-router-dom";
import { editorTypes } from "../../../helpers/constant";
import EditorCard from "../../../components/website/editorPage/EditorCard";
import { Loader } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { fetchJournalById } from "../../../redux/slices/webJournal";

const AdvisoryBoard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { journalId } = useParams();

  const { journalDetails, journalLoading } = useSelector(
    (state) => state.webJournalReducer
  );

  const [advisoryBoardList, setAdvisoryBoardList] = useState({
    loading: false,
    data: [],
  });

  useEffect(() => {
    if (journalId && !journalDetails) {
      setAdvisoryBoardList((prev) => ({
        ...prev,
        loading: true,
      }));
      dispatch(fetchJournalById({ journalCustomId: journalId }));
    }
  }, [journalId, dispatch]);

  useEffect(() => {
    const fetchEditorList = async () => {
      setAdvisoryBoardList((prev) => ({
        ...prev,
        loading: true,
      }));
      const resEditorList = await editorWebsiteServices.getEditorListWebsite({
        journal: journalDetails?._id,
        designation: editorTypes.ADVISORY_BOARD.tag,
      });

      if (resEditorList.data) {
        setAdvisoryBoardList((prev) => ({
          ...prev,
          loading: false,
          data: resEditorList.data,
        }));
      } else {
        setAdvisoryBoardList((prev) => ({
          ...prev,
          loading: false,
        }));
      }
    };

    if (journalDetails?._id) {
      fetchEditorList();
    }
  }, [journalDetails?._id]);

  return (
    <Box
      sx={{
        padding: "20px",
        height: "100%",
        background: theme.palette.primary.litSurface,
      }}
    >
      {journalLoading || advisoryBoardList?.loading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Loader />
        </Box>
      ) : advisoryBoardList?.data?.length > 0 ? (
        <>
          <Grid container>
            <Grid item xs={12} sm={3} md={3}>
              <Box
                sx={{
                  maxWidth: "100%",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={`${process.env.REACT_APP_FILE_BASE_URL}${journalDetails?.coverImgUrl}`}
                  alt="journal cover"
                  style={{
                    maxWidth: "66%",
                    borderRadius: theme.borderRadius[10],
                  }}
                />
              </Box>
            </Grid>
            <Grid item container xs={12} sm={9} md={9}>
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontWeight: 600,
                }}
              >
                Publisher: International Federation of Research and Discovery
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  wordWrap: "break-word",
                }}
              >
                Our accomplished board of experts is dedicated to upholding the
                highest standards of research and scholarship, ensuring the
                integrity and excellence of our publications.
              </Typography>

              <Box
                sx={{
                  borderRadius: theme.borderRadius[10],
                  overflow: "hidden",
                  marginTop: "30px",
                  backgroundColor: theme.palette.neutral[10],
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    padding: "5px 10px",
                    background: theme.palette.primary.main,
                    textAlign: "center",
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      wordWrap: "break-word",
                      fontWeight: 400,
                    }}
                  >
                    {editorTypes.ADVISORY_BOARD.name}
                  </Typography>
                </Box>
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: "10px",
                  }}
                >
                  {advisoryBoardList?.data?.map((editor) => (
                    <Grid container item xs={12} sm={12} md={6} lg={6}>
                      <EditorCard
                        editorDetails={editor}
                        gridContainerProps={{
                          item: true,
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
          }}
        >
          <Typography
            variant="body1"
            sx={{
              wordWrap: "break-word",
            }}
          >
            No Advisory board Available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default AdvisoryBoard;
