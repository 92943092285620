import axiosInstance from "../../axiosInstance";

/**
 * Get all article Submissions
 * @param {*} page
 * @param {*} limit
 * @returns
 */
const getAllMemberSubmissionList = async ({ page, limit }) => {
  try {
    const res = await axiosInstance.get(
      `/api/memberSubmission/list?page=${page}&limit=${limit}`
    );

    if (res.status === 200) {
      return res.data;
    } else {
    }
  } catch (error) {}
};

export default getAllMemberSubmissionList;
