import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { CustomReactSelect, CustomTextField } from "../../../components";
import { MuiTelInput } from "mui-tel-input";
import { useDropzone } from "react-dropzone";
import journalWebsiteServices from "../../../services/journal/website";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import memberSubmissionWebsiteServices from "../../../services/memberSubmission/website";
import { joinMemberTypeOptions } from "../../../helpers/constant";
import { useSearchParams } from "react-router-dom";

const StyledDropZoneContainer = styled(Box, {
  shouldForwardProp: (props) => ["error"].indexOf(props) === -1,
})(({ theme, error }) => ({
  border: error
    ? `1px dashed ${theme.palette.danger.main}`
    : `1px dashed ${theme.palette.neutral[70]}`,
  background: theme.palette.neutral[10],
  width: "100%",
  height: "200px",
  borderRadius: theme.borderRadius[3],
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  flexDirection: "column",
  padding: "10px",
  "& h5 strong": {
    color: theme.palette.danger.main,
  },
  "& p": {
    fontSize: "12px",
  },
}));

const JoinAsReviewerEditor = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const journalValidationSchema = yup.object().shape({
    joinType: yup.object().shape({
      name: yup.string(),
    }),
    name: yup.string().required("Name is required"),
    designation: yup.string().required("Designation is required"),
    email: yup.string().required("Email is required"),
    phone: yup.string().required("Phone is required"),
    journal: yup.object().shape({
      name: yup.string(),
      _id: yup.string().required("Journal is required"),
    }),
  });

  const {
    control,
    handleSubmit,
    resetField,
    formState: { errors },
    getValues,
  } = useForm({
    defaultValues: {
      joinType: "",
      name: "",
      designation: "",
      email: "",
      phone: "",
      journal: {
        name: "",
        _id: "",
      },
    },
    resolver: yupResolver(journalValidationSchema),
  });

  const [journalOptions, setJournalOptions] = useState([]);

  const [photoFile, setPhotoFile] = useState({
    file: null,
    error: "",
  });

  const [cvFile, setCvFile] = useState({
    file: null,
    error: "",
  });

  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const onPhotoDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      setPhotoFile({ file: acceptedFiles[0], error: "" });
    }
  }, []);

  //
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onPhotoDrop,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
    multiple: false,
  });

  const onCvDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles?.length > 0) {
      setCvFile({ file: acceptedFiles[0], error: "" });
    }
  }, []);

  const cvDropzone = useDropzone({
    onDrop: onCvDrop,
    accept: {
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/pdf": [".pdf"],
      // "text/plain": [".txt"],
    },
    multiple: false,
  });

  useEffect(() => {
    if (searchParams.get("type")) {
      const joiningType = joinMemberTypeOptions.find(
        (type) => type.value === searchParams.get("type").toUpperCase()
      );
      resetField("joinType", { defaultValue: joiningType });
    }
  }, [searchParams]);

  useEffect(() => {
    const fetchJournalOptions = async () => {
      const resJournalOptions =
        await journalWebsiteServices.getJournalListWebsite({
          subjectId: "",
          sortBy: "",
          sortDirection: "",
          page: "",
          limit: "",
        });

      setJournalOptions(resJournalOptions?.data?.docs);
      // if (searchParams.get("journalId")) {
      //   const preselectedJournal = resJournalOptions?.data?.docs?.find(
      //     (journal) => journal?._id === searchParams.get("journalId")
      //   );
      //   resetField("journal", { defaultValue: preselectedJournal });
      // }
    };
    fetchJournalOptions();
  }, []);

  const onSubmit = async (data) => {
    if (!photoFile.file) {
      setPhotoFile((prev) => ({
        ...prev,
        error: "Please select your photo.",
      }));
      return;
    }

    if (!cvFile.file) {
      setPhotoFile((prev) => ({
        ...prev,
        error: "Please select your CV/Resume.",
      }));
      return;
    }

    setSubmitLoading(true);

    const submitMemberFormData = new FormData();
    submitMemberFormData.append("joinType", data.joinType.value);
    submitMemberFormData.append("name", data.name);
    submitMemberFormData.append("designation", data.designation);
    submitMemberFormData.append("email", data.email);
    submitMemberFormData.append("phone", data.phone);
    submitMemberFormData.append("journal", data.journal._id);
    submitMemberFormData.append("photoFile", photoFile.file);
    submitMemberFormData.append("cvFile", cvFile.file);

    const resSubmitMember =
      await memberSubmissionWebsiteServices.createMemberSubmission(
        submitMemberFormData
      );

    if (resSubmitMember) {
      setShowSuccessPage(true);
      setSubmitLoading(false);
    } else {
      setSubmitLoading(false);
    }
  };

  return (
    <Box
      sx={{
        padding: "20px 10%",
        height: "100%",
        background: theme.palette.primary.litSurface,
      }}
    >
      {showSuccessPage ? (
        <Box
          sx={{
            padding: "20px",
            height: "100vh",
            background: theme.palette.neutral[0],
            borderRadius: theme.borderRadius[8],
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <CheckCircleIcon
            sx={{ fontSize: 100, color: theme.palette.success.main }}
          />
          <Typography
            variant="h6"
            gutterBottom
            sx={{
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Thank you for your submission.
          </Typography>
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              fontWeight: 600,
              textAlign: "center",
              "& span": {
                color: theme.palette.secondary.main,
              },
            }}
          >
            {/* Your Manuscript Tracking ID is <span>{trackingId}</span>. */}
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            sx={{
              fontWeight: 600,
              textAlign: "center",
              color: theme.palette.neutral[80],
            }}
          >
            Our team will get in touch with you at your provided email address
            within 24 to 48 hours.
          </Typography>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              padding: "20px",
              height: "100%",
              background: theme.palette.neutral[0],
              borderRadius: theme.borderRadius[8],
            }}
          >
            <Typography
              variant="h6"
              gutterBottom
              sx={{
                // fontWeight: 600,
                marginBottom: "50px",
                textAlign: "center",
              }}
            >
              Drive The Discovery Forward and Together Let's shape the Future By
              Joining as Editor / Reviewer
            </Typography>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2} alignItems={"flex-end"}>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="joinType"
                    control={control}
                    render={({ field }) => (
                      <CustomReactSelect
                        label={"Join As *"}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e.value}
                        options={joinMemberTypeOptions}
                        placeholder={"- - -"}
                        helperText={errors?.joinType?.message}
                        error={errors?.joinType?.message}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 5,
                          }),
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label="Full Name *"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        helperText={errors?.name?.message}
                        error={errors?.name?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="designation"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label="Designation *"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        helperText={errors?.designation?.message}
                        error={errors?.designation?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <CustomTextField
                        label="Email *"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        helperText={errors?.email?.message}
                        error={errors?.email?.message}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="phone"
                    control={control}
                    render={({ field }) => (
                      <MuiTelInput
                        label="Phone Number with country Code *"
                        variant="outlined"
                        size="small"
                        type="text"
                        fullWidth
                        helperText={errors?.phone?.message}
                        error={errors?.phone?.message}
                        defaultCountry="IN"
                        sx={{
                          "& label": {
                            "&.Mui-focused": {
                              color: theme.palette.neutral[90],
                            },
                          },
                          "& .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                            {
                              borderColor: theme.palette.neutral[50],
                            },
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Controller
                    name="journal"
                    control={control}
                    render={({ field }) => (
                      <CustomReactSelect
                        label={"Choose Journal *"}
                        getOptionLabel={(e) => e.name}
                        getOptionValue={(e) => e._id}
                        options={journalOptions}
                        placeholder={"- - -"}
                        helperText={errors?.journal?._id?.message}
                        error={errors?.journal?._id?.message}
                        styles={{
                          menu: (base) => ({
                            ...base,
                            zIndex: 5,
                          }),
                        }}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {photoFile.file ? (
                    <StyledDropZoneContainer>
                      <Box sx={{ position: "relative" }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setPhotoFile({
                              file: null,
                              error: "Please Select a file.",
                            })
                          }
                          edge="end"
                          sx={{
                            position: "absolute",
                            top: -15,
                            right: -10,
                            zIndex: 1,
                            background: theme.palette.neutral[0],
                            color: theme.palette.secondary.main,
                          }}
                          size="small"
                        >
                          <i class="ri-delete-bin-2-fill ri-sm"></i>
                        </IconButton>
                        <Box
                          sx={{
                            background: theme.palette.neutral[0],
                            padding: "10px 10px",
                            borderRadius: theme.borderRadius[8],
                          }}
                        >
                          <Typography
                            variant="body1"
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            {photoFile?.file?.name}
                          </Typography>
                        </Box>
                      </Box>
                    </StyledDropZoneContainer>
                  ) : (
                    <StyledDropZoneContainer
                      {...getRootProps()}
                      error={photoFile.error}
                    >
                      <input {...getInputProps()} />
                      {isDragActive ? (
                        <Box width={"100%"}>
                          <p>Drop the files here ...</p>
                        </Box>
                      ) : (
                        <Box>
                          {photoFile.error && (
                            <h5>
                              <strong>{photoFile.error}</strong>
                            </h5>
                          )}
                          <h5>Upload your Photo *</h5>
                          <h5>
                            <strong>
                              Accepts only .png, .jpeg, .jpg file type
                            </strong>
                          </h5>

                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        </Box>
                      )}
                    </StyledDropZoneContainer>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  {cvFile.file ? (
                    <StyledDropZoneContainer>
                      <Box sx={{ position: "relative" }}>
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setCvFile({
                              file: null,
                              error: "Please Select a file.",
                            })
                          }
                          edge="end"
                          sx={{
                            position: "absolute",
                            top: -15,
                            right: -10,
                            zIndex: 1,
                            background: theme.palette.neutral[0],
                            color: theme.palette.secondary.main,
                          }}
                          size="small"
                        >
                          <i class="ri-delete-bin-2-fill ri-sm"></i>
                        </IconButton>
                        <Box
                          sx={{
                            background: theme.palette.neutral[0],
                            padding: "10px 10px",
                            borderRadius: theme.borderRadius[8],
                          }}
                        >
                          <Typography
                            variant="body1"
                            gutterBottom
                            sx={{
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            {cvFile?.file?.name}
                          </Typography>
                        </Box>
                      </Box>
                    </StyledDropZoneContainer>
                  ) : (
                    <StyledDropZoneContainer
                      {...cvDropzone.getRootProps()}
                      error={cvFile.error}
                    >
                      <input {...cvDropzone.getInputProps()} />
                      {cvDropzone.isDragActive ? (
                        <Box width={"100%"}>
                          <p>Drop the files here ...</p>
                        </Box>
                      ) : (
                        <Box>
                          {cvFile.error && (
                            <h5>
                              <strong>{cvFile.error}</strong>
                            </h5>
                          )}
                          <h5>Upload Your CV/Resume *</h5>
                          <h5>
                            <strong>
                              Accepts only .pdf, .docx, .doc, file type
                            </strong>
                          </h5>

                          <p>
                            Drag 'n' drop some files here, or click to select
                            files
                          </p>
                        </Box>
                      )}
                    </StyledDropZoneContainer>
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{
                      "&.MuiButton-root": {
                        color: theme.palette.neutral[0],
                        width: "100%",

                        borderRadius: theme.borderRadius[10],
                        backgroundColor: theme.palette.secondary.main,
                        textTransform: "none",
                        "&:hover": {
                          backgroundColor: theme.palette.secondary.hover,
                        },
                      },
                      "&.Mui-disabled": {
                        backgroundColor: theme.palette.neutral[30],
                      },
                    }}
                    size="large"
                    type="submit"
                    endIcon={submitLoading && <CircularProgress size={20} />}
                    disabled={submitLoading || !photoFile.file || !cvFile.file}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </>
      )}
    </Box>
  );
};

export default JoinAsReviewerEditor;
